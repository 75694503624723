<template>
  <div>
    <KTCodePreview v-bind:title="'Thông tin chương trình'">
      <template v-slot:preview>
        <b-form ref="form" lazy-validation class="row">
          <!-- Input session -->
          <b-container class="bv-example-row">
            <b-row>
              <b-col cols="6">
                <b-row>
                  <b-col>
                    <b-form-group
                      label-align-md="left"
                      class="required-control"
                    >
                      <label>Tên chương trình:</label>
                      <b-form-input
                        size="sm"
                        v-model="$v.mainModel.name.$model"
                        placeholder="Nhập tên"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group>
                      <label>Mô tả:</label>
                      <b-form-textarea
                        v-model="mainModel.description"
                        placeholder="Nhập mô tả"
                        rows="5"
                        :max-length="255"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="6">
                <b-row>
                  <b-col cols="6">
                    <b-form-group
                      label-align-md="left"
                      required
                      class="required-control"
                    >
                      <label>Từ ngày:</label>
                      <date-picker
                        placeholder="Từ ngày"
                        class="form-control form-control-sm"
                        :config="dpConfigs.date"
                        v-model="mainModel.startDate"
                      ></date-picker>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label>Đến ngày:</label>
                      <date-picker
                        placeholder="Đến ngày"
                        class="form-control form-control-sm"
                        :config="dpConfigs.date"
                        v-model="mainModel.endDate"
                      ></date-picker>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6">
                    <b-form-group>
                      <label>Trạng thái:</label>
                      <b-form-select
                        size="sm"
                        v-model="mainModel.isActive"
                        :options="listStatus"
                        required
                        value-field="id"
                        text-field="name"
                        disabled-field="notEnabled"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <hr class="hr-text" data-content="" style="font-weight: 600" />
            <h4>Điều kiện đạt gói thưởng</h4>
            <b-row>
              <b-col md="3">
                <b-form-group>
                  <label>1) Doanh thu:</label>
                  <div class="input-group input-group-sm mb-3">
                    <input
                      type="text"
                      class="form-control text-right"
                      placeholder="Nhập mục tiêu doanh thu"
                      v-model="mainModel.incomeTarget"
                      aria-describedby="input-income-target"
                      aria-label="Small"
                      v-mask="mask"
                    />
                    <div class="input-group-append">
                      <span
                        class="input-group-text"
                        style="color: #ffffff; background: #2ba0cb; width: 45px"
                        id="input-income-target"
                        >VND</span
                      >
                    </div>
                  </div>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group class="col-md-6">
                  <label>Tỷ trọng:</label>
                  <div class="input-group input-group-sm mb-3">
                    <input
                      type="text"
                      class="form-control text-right"
                      placeholder="Nhập tỷ trọng doanh thu"
                      v-model="mainModel.incomePoint"
                      aria-describedby="input-income-point"
                      aria-label="Small"
                      v-mask="mask"
                    />
                    <div class="input-group-append">
                      <span
                        class="input-group-text"
                        style="color: #ffffff; background: #cb2b6e; width: 30px"
                        id="input-income-point"
                        >%</span
                      >
                    </div>
                  </div>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label>2) Lợi nhuận MIN:</label>
                  <div class="input-group input-group-sm mb-3">
                    <input
                      type="text"
                      class="form-control text-right"
                      placeholder="Nhập mục tiêu lợi nhuận"
                      v-model="mainModel.grossProfitTarget"
                      aria-describedby="input-gross-profit-target"
                      aria-label="Small"
                      v-mask="mask"
                    />
                    <div class="input-group-append">
                      <span
                        class="input-group-text"
                        style="color: #ffffff; background: #2ba0cb; width: 45px"
                        id="input-gross-profit-target"
                        >VND</span
                      >
                    </div>
                  </div>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group class="col-md-6">
                  <label>Tỷ trọng:</label>
                  <div class="input-group input-group-sm mb-3">
                    <input
                      type="text"
                      class="form-control text-right"
                      placeholder="Nhập tỷ trọng lợi nhuận"
                      v-model="mainModel.grossProfitPoint"
                      aria-describedby="input-gross-profit-point"
                      aria-label="Small"
                      v-mask="mask"
                    />
                    <div class="input-group-append">
                      <span
                        class="input-group-text"
                        style="color: #ffffff; background: #cb2b6e; width: 30px"
                        id="input-gross-profit-point"
                        >%</span
                      >
                    </div>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="3">
                <b-form-group>
                  <label>3) Giá trị tồn kho/ Doanh thu thực bán </label>
                  <div class="input-group input-group-sm mb-3">
                    <input
                      type="text"
                      class="form-control text-right"
                      placeholder="Nhập mục tiêu doanh thu"
                      v-model="mainModel.inventoryValueTarget"
                      aria-describedby="input-inventory-value-target"
                      aria-label="Small"
                      v-mask="mask"
                    />
                    <div class="input-group-append">
                      <span
                        class="input-group-text"
                        style="color: #ffffff; background: #eb9d3e; width: 45px"
                        id="input-inventory-value-target"
                        >lần</span
                      >
                    </div>
                  </div>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group class="col-md-6">
                  <label>Tỷ trọng:</label>
                  <div class="input-group input-group-sm mb-3">
                    <input
                      type="text"
                      class="form-control text-right"
                      placeholder="Nhập tỷ trọng giá trị tồn kho"
                      v-model="mainModel.inventoryValuePoint"
                      aria-describedby="input-inventory-value-point"
                      aria-label="Small"
                      v-mask="mask"
                    />
                    <div class="input-group-append">
                      <span
                        class="input-group-text"
                        style="color: #ffffff; background: #cb2b6e; width: 30px"
                        id="input-inventory-value-point"
                        >%</span
                      >
                    </div>
                  </div>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label>4) Thời gian lưu kho:</label>
                  <div class="input-group input-group-sm mb-3">
                    <input
                      type="text"
                      class="form-control text-right"
                      placeholder="Nhập hạn mức thời gian lưu kho"
                      v-model="mainModel.inventoryTimeTarget"
                      aria-describedby="input-inventory-time-target"
                      aria-label="Small"
                      v-mask="mask"
                    />
                    <div class="input-group-append">
                      <span
                        class="input-group-text"
                        style="color: #ffffff; background: #2dd798; width: 45px"
                        id="input-inventory-time-target"
                        >tháng</span
                      >
                    </div>
                  </div>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group class="col-md-6">
                  <label>Tỷ trọng:</label>
                  <div class="input-group input-group-sm mb-3">
                    <input
                      type="text"
                      class="form-control text-right"
                      placeholder="Nhập mục tiêu doanh thu"
                      v-model="mainModel.inventoryTimePoint"
                      aria-describedby="input-inventory-time-point"
                      aria-label="Small"
                      v-mask="mask"
                    />
                    <div class="input-group-append">
                      <span
                        class="input-group-text"
                        style="color: #ffffff; background: #cb2b6e; width: 30px"
                        id="input-inventory-time-point"
                        >%</span
                      >
                    </div>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <hr class="hr-text" data-content="" style="font-weight: 600" />
            <h4 class="mt-4 mb-4">Chi tiết hoa hồng</h4>
            <b-row>
              <b-col md="12">
                <b-form-group class="col-md-12 pl-0">
                  <label>Điểm thưởng mỗi sản phẩm (VND)</label>
                  <b-row>
                    <b-col md="12">
                      <b-form-radio
                        v-model="mainModel.baseBonusPointType"
                        name="some-radios"
                        value="basic"
                        >Cở bản</b-form-radio
                      >
                    </b-col>
                    <b-col md="12">
                      <b-row>
                        <b-col md="3">
                          <span>Điểm thưởng</span>
                          <b-form-input
                            size="sm"
                            type="text"
                            v-model="mainModel.baseBonusPoint"
                            placeholder="Nhập điểm thưởng"
                            class="text-right col-12"
                            v-mask="mask"
                            :disabled="mainModel.baseBonusPointType !== 'basic'"
                            @input="onInputBonusPoint"
                          ></b-form-input>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col md="12">---</b-col>
                    <b-col md="12">
                      <b-form-radio
                        v-model="mainModel.baseBonusPointType"
                        name="some-radios"
                        value="advanced"
                        >Mở rộng</b-form-radio
                      >
                    </b-col>
                    <b-col md="12">
                      <b-row>
                        <b-col md="3">
                          <span>Điểm thưởng bán buôn</span>
                          <b-form-input
                            size="sm"
                            type="text"
                            v-model="mainModel.baseWholesaleBonusPoint"
                            placeholder="Nhập điểm thưởng bán buôn"
                            class="text-right col-12"
                            v-mask="mask"
                            :disabled="
                              mainModel.baseBonusPointType !== 'advanced'
                            "
                            @input="onInputBonusPoint"
                          ></b-form-input>
                        </b-col>
                        <b-col md="3">
                          <span>Điểm thưởng bán lẻ</span>
                          <b-form-input
                            size="sm"
                            type="text"
                            v-model="mainModel.baseRetailBonusPoint"
                            placeholder="Nhập điểm thưởng bán lẻ"
                            class="text-right col-12"
                            v-mask="mask"
                            :disabled="
                              mainModel.baseBonusPointType !== 'advanced'
                            "
                            @input="onInputBonusPoint"
                          ></b-form-input>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="3">
                <b-form-group id="input-group-search">
                  <label>Giám đốc ngành hàng</label>
                  <vue-autosuggest
                    class="pl-0"
                    v-model="searchDirector"
                    :suggestions="filteredOptionsEmployee"
                    @selected="onSelectedDirector"
                    :limit="10"
                    @input="onInputChangeDirector"
                    :input-props="{
                      class: 'autosuggest__input',
                      placeholder: 'Nhập tên giám đốc ngành hàng',
                      style: 'border-radius:0px!important',
                    }"
                  >
                    <template slot-scope="{ suggestion }">
                      <div>{{ suggestion.item.name }}</div>
                    </template>
                  </vue-autosuggest>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-form-group class="col-md-12">
                <label>Tìm nhóm sản phẩm:</label>
                <b-col md="12" class="row pr-0">
                  <b-col md="6" class="p-0">
                    <vue-autosuggest
                      v-model="searchProductCate"
                      :suggestions="filteredOptions"
                      @selected="onSelectedProductCate"
                      :limit="10"
                      @input="onInputChangeProductCate"
                      :input-props="{
                        class: 'autosuggest__input',
                        placeholder: 'Nhập tên nhóm sản phẩm',
                        style: 'border-radius:0px!important',
                      }"
                      :should-render-suggestions="
                        (size, loading) => size >= 0 && !loading
                      "
                    >
                      <template slot-scope="{ suggestion }">
                        <div>{{ suggestion.item.name }}</div>
                      </template>
                    </vue-autosuggest>
                  </b-col>
                  <b-col md="2"></b-col>
                  <b-col md="2"></b-col>
                  <b-col md="2"></b-col>
                </b-col>
              </b-form-group>
            </b-row>
            <b-row>
              <b-col cols="12" md="12">
                <div>
                  <b-table
                    bordered
                    hover
                    :fields="fields"
                    :items="mainModel.details"
                    v-if="renderComponent"
                  >
                    <template v-slot:cell(grossProfitTarget)="row">
                      <b-input
                        type="text"
                        size="sm"
                        class="input-style text-right"
                        v-model="row.item.grossProfitTarget"
                        v-mask="mask"
                      ></b-input>
                    </template>

                    <template v-slot:cell(incomeTarget)="row">
                      <b-input
                        type="text"
                        size="sm"
                        class="input-style text-right"
                        v-model="row.item.incomeTarget"
                        v-mask="mask"
                      ></b-input>
                    </template>
                    <template v-slot:cell(isBelongDirector)="row">
                      <b-form-checkbox
                        v-model="row.item.isBelongDirector"
                        size="lg"
                      ></b-form-checkbox>
                    </template>
                    <template v-slot:cell(actions)="row">
                      <div class="d-flex justify-content-center">
                        <b-dropdown size="sm" id="dropdown-left" no-caret right>
                          <template slot="button-content">
                            <i
                              style="font-size: 1rem; padding-right: 0px"
                              class="flaticon2-settings"
                            ></i>
                          </template>
                          <b-dropdown-item
                            @click="showModalEmployeeRewardDetail(row.item)"
                          >
                            <span style="color: #3f4254; font-size: 12px">
                              <i
                                style="font-size: 1rem"
                                class="flaticon2-pen"
                              ></i>
                              &nbsp; Chỉnh sửa
                            </span>
                          </b-dropdown-item>
                          <b-dropdown-item @click="showDeleteAlert(row.item)">
                            <span style="color: #3f4254; font-size: 12px">
                              <i
                                style="font-size: 1rem; color: #d33"
                                class="flaticon2-rubbish-bin-delete-button"
                              ></i>
                              &nbsp; Xóa
                            </span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </template>
                  </b-table>
                </div>
              </b-col>
              <b-col cols="6" md="4"></b-col>
            </b-row>
            <hr class="hr-text" data-content="" style="font-weight: 600" />
            <h4>Danh sách chi phí</h4>
            <RewardFee :items="mainModel.rewardFees" v-on:onDelete="onDelete" />
          </b-container>
        </b-form>
        <b-modal
          ref="employee-reward-detail-modal"
          hide-footer
          title="Cài đặt chi tiết hoa hồng nhân viên"
          id="modal-prevent-closing"
          size="lg"
        >
          <b-container class="mt-4 pl-0 pr-0">
            <h5>{{ detailEmployeeReward.name }}</h5>
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <label>Mục tiêu DT(VND):</label>
                  <b-form-input
                    size="sm"
                    type="text"
                    v-model="detailEmployeeReward.incomeTarget"
                    placeholder="Nhập mục tiêu DT(VND)"
                    class="text-right col-6 pl-0"
                    v-mask="mask"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <label>Lợi nhuận MIN:</label>
                  <b-form-input
                    size="sm"
                    type="text"
                    v-model="detailEmployeeReward.grossProfitTarget"
                    placeholder="Nhập mục tiêu lợi nhuận"
                    class="text-right col-6 pl-0"
                    v-mask="mask"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <label>NV phụ trách:</label>
                  <vue-autosuggest
                    v-model="detailEmployeeReward.inchargeEmployeeName"
                    :suggestions="filteredOptionsEmployee"
                    @selected="onSelectedInchargeEmployee"
                    :limit="10"
                    @input="onInputChangeInchargeEmployee"
                    :input-props="{
                      class: 'autosuggest__input',
                      placeholder: 'Nhập NV phụ trách',
                      style: 'border-radius:0px!important',
                    }"
                  >
                    <template slot-scope="{ suggestion }">
                      <div>{{ suggestion.item.name }}</div>
                    </template>
                  </vue-autosuggest>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <b-form-group class="col-md-12 pl-0">
                  <label>Điểm thưởng mỗi sản phẩm (VND):</label>
                  <b-row>
                    <b-col md="12">
                      <b-form-radio
                        v-model="detailEmployeeReward.baseBonusPointType"
                        name="some-radios"
                        value="basic"
                        >Cở bản</b-form-radio
                      >
                    </b-col>
                    <b-col md="12">
                      <b-row>
                        <b-col md="4">
                          <span>Điểm thưởng</span>
                          <b-form-input
                            size="sm"
                            type="text"
                            v-model="detailEmployeeReward.baseBonusPoint"
                            placeholder="Nhập điểm thưởng"
                            class="text-right col-12"
                            v-mask="mask"
                            :disabled="
                              detailEmployeeReward.baseBonusPointType !==
                              'basic'
                            "
                            @input="onInputBonusPointDetail"
                          ></b-form-input>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col md="12">---</b-col>
                    <b-col md="12">
                      <b-form-radio
                        v-model="detailEmployeeReward.baseBonusPointType"
                        name="some-radios"
                        value="advanced"
                        >Mở rộng</b-form-radio
                      >
                    </b-col>
                    <b-col md="12">
                      <b-row>
                        <b-col md="4">
                          <span>Điểm thưởng bán buôn</span>
                          <b-form-input
                            size="sm"
                            type="text"
                            v-model="
                              detailEmployeeReward.baseWholesaleBonusPoint
                            "
                            placeholder="Nhập điểm thưởng bán buôn"
                            class="text-right col-12"
                            v-mask="mask"
                            :disabled="
                              detailEmployeeReward.baseBonusPointType !==
                              'advanced'
                            "
                            @input="onInputBonusPointDetail"
                          ></b-form-input>
                        </b-col>
                        <b-col md="4">
                          <span>Điểm thưởng bán lẻ</span>
                          <b-form-input
                            size="sm"
                            type="text"
                            v-model="detailEmployeeReward.baseRetailBonusPoint"
                            placeholder="Nhập điểm thưởng bán lẻ"
                            class="text-right col-12"
                            v-mask="mask"
                            :disabled="
                              detailEmployeeReward.baseBonusPointType !==
                              'advanced'
                            "
                            @input="onInputBonusPointDetail"
                          ></b-form-input>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <label>Doanh thu hoá đơn:</label>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label>Giá trị 1:</label>
                  <b-form-input
                    size="sm"
                    type="text"
                    v-model="detailEmployeeReward.billAmount1"
                    placeholder="Nhập DT 1"
                    class="text-right col-12 pl-0"
                    v-mask="mask"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group>
                  <label>Toán tử 1</label>
                  <b-form-select
                    v-model="detailEmployeeReward.billAmountOperator1"
                    size="sm"
                  >
                    <b-form-select-option value="1" class="text-center">
                      --
                    </b-form-select-option>
                    <b-form-select-option value="2">
                      &lt;
                    </b-form-select-option>
                    <b-form-select-option value="4">
                      &le;
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="2" class="text-center">
                <b-row>
                  <b-col md="12"> - </b-col>
                  <b-col md="12"> DT Hoá Đơn </b-col>
                  <b-col md="12"> - </b-col>
                </b-row>
              </b-col>
              <b-col md="2">
                <b-form-group>
                  <label>Toán tử 2</label>
                  <b-form-select
                    v-model="detailEmployeeReward.billAmountOperator2"
                    size="sm"
                  >
                    <b-form-select-option value="1" class="text-center">
                      --
                    </b-form-select-option>
                    <b-form-select-option value="2">
                      &lt;
                    </b-form-select-option>
                    <b-form-select-option value="4">
                      &le;
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label>Giá trị 2</label>
                  <b-form-input
                    size="sm"
                    type="text"
                    v-model="detailEmployeeReward.billAmount2"
                    placeholder="Nhập DT 2"
                    class="text-right col-12 pl-0"
                    v-mask="mask"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-container>
          <b-button
            style="fontweight: 600; width: 70px"
            variant="primary"
            size="sm"
            @click="saveEmployeeRewardDetail"
            >Lưu</b-button
          >
          <b-button
            style="margin-left: 10px; font-weight: 600; width: 70px"
            variant="secondary"
            size="sm"
            @click="hideModalEmployeeRewardDetail"
            >Hủy</b-button
          >
        </b-modal>
      </template>
      <template v-slot:foot>
        <!-- <b-col lg="4" class="pb-2"> -->
        <b-container class="bv-example-row">
          <b-row>
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              type="submit"
              @click="onSubmit"
              >Lưu</b-button
            >
            <!-- </b-col> -->
            <!-- <b-col lg="2" class="pb-2"> -->
            <b-button
              style="margin-left: 10px; font-weight: 600; width: 70px"
              variant="secondary"
              size="sm"
              @click="onBack"
              >Hủy</b-button
            >
            <b-col></b-col>
            <b-col></b-col>
            <b-col></b-col>
          </b-row>
        </b-container>
      </template>
    </KTCodePreview>
  </div>
</template>
<style>
.multi-select-wrapper {
  flex: 1 1 auto;
}

.center-label label {
  margin-bottom: 0px;
}

.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 999;
  width: 100%;
  border: 1px solid #e0e0e0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

.autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import Swal from 'sweetalert2';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import moment from 'moment';
import { validationMixin } from 'vuelidate';
import { required, minValue } from 'vuelidate/lib/validators';
import { getToastInstance } from './../../../utils/toastHelper';
import _ from 'lodash';
import { currencyMask, unMaskPrice } from './../../../utils/common';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import decounce from 'debounce';
import { TIME_TRIGGER } from './../../../utils/constants';
import { v4 } from 'uuid';
import { xoa_dau } from './../../../utils/common';
import RewardFee from '@/view/components/rewards/RewardFee.vue';

export default {
  mixins: [validationMixin],
  data() {
    return {
      renderComponent: true,
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      mask: currencyMask,
      mainModel: {
        id: null,
        name: '',
        description: '',
        startDate: new Date(),
        endDate: null,
        details: [],
        isActive: true,
        grossProfitTarget: 0,
        grossProfitPoint: 0,
        incomeTarget: 0,
        incomePoint: 0,
        inventoryValueTarget: 0,
        inventoryValuePoint: 0,
        inventoryTimePoint: 0,
        inventoryTimeTarget: 0,
        directorId: null,
        rewardFees: [],
        baseBonusPoint: 0,
        baseBonusPointType: 'basic',
        baseRetailBonusPoint: 0,
        baseWholesaleBonusPoint: 0,
      },
      detailEmployeeReward: {
        id: null,
        name: null,
        grossProfitTarget: 0,
        incomeTarget: 0,
        inchargeEmployeeName: '',
        inchargeEmployeeRewardRate: 0,
        adminId: null,
        adminName: '',
        adminRewardRate: 0,
        billAmount1: 0,
        billAmountOperator1: 1,
        billAmount2: 0,
        billAmountOperator2: 1,
        count: null,
        baseBonusPoint: 0,
        baseBonusPointType: 'basic',
        baseRetailBonusPoint: 0,
        baseWholesaleBonusPoint: 0,
      },
      fields: [
        {
          key: 'count',
          label: 'STT',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '5%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'name',
          label: 'Tên nhóm',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '25%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'incomeTarget',
          label: 'Mục tiêu DT (VND)',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '30%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'grossProfitTarget',
          label: 'Lợi nhuận MIN (VND)',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '30%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'isBelongDirector',
          label: 'Thuộc GĐ',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '5%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'actions',
          label: '',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '5%',
          },
        },
      ],
      filteredOptions: [],
      options: [
        {
          data: [],
        },
      ],
      searchProductCate: '',
      listStatus: [
        {
          id: true,
          name: 'Hoạt động',
        },
        {
          id: false,
          name: 'Không hoạt động',
        },
      ],
      listTypeSearch: [
        {
          id: 1,
          name: 'Sản phẩm IMEI',
        },
        {
          id: 2,
          name: 'Sản phẩm',
        },
      ],
      selectTypeSearch: 1,
      isSearching: false,
      searchDirector: '',
      searchDirectorAdmin: '',
      filteredOptionsEmployee: [],
      optionsEmployee: [
        {
          data: [],
        },
      ],
      currentindex: 0,
    };
  },
  validations: {
    mainModel: {
      startDate: {
        required,
        minValue: minValue(0),
      },
      name: {
        required,
      },
    },
  },
  components: {
    KTCodePreview,
    datePicker,
    RewardFee,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: 'Chương trình thưởng NH máy',
        route: '/reward-phone/info-reward-phone',
      },
      {
        title: 'Thông tin chương trình thưởng NH máy',
      },
    ]);
  },
  computed: {},
  created() {
    this.getListEmployee();
    this.mainModel.id = this.$route.query.id;
    if (this.mainModel.id) {
      this.getInfoById();
    }
  },
  methods: {
    ...getToastInstance(),
    forceRerender() {
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    formatMoney(number) {
      const money = new Intl.NumberFormat('vn-VN').format(number);
      return _.isNaN(money) ? 0 : money;
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa sản phẩm!',
        text: 'Bạn có chắc muốn xóa sản phẩm này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        position: 'top',
      }).then((result) => {
        if (result.dismiss != 'cancel') {
          this.mainModel.details = _.reject(
            this.mainModel.details,
            (product) => {
              return product.count == item.count;
            }
          );
          this.mainModel.details.forEach((element, index) => {
            element.count = index + 1;
          });
        }
      });
    },
    fetchProductCate(textSearch) {
      this.isSearching = true;
      ApiService.get(`rewardProductCategory/search?name=${textSearch}`).then(
        ({ data }) => {
          this.options[0].data = data.data;
          this.filteredOptions = [
            {
              data: this.options[0].data,
            },
          ];
          this.isSearching = false;
          if (textSearch !== this.searchProductCate) {
            const nextTextSearch = this.searchProductCate;
            this.fetchProductCate(nextTextSearch);
          }
        }
      );
    },
    onSelectedProductCate(option) {
      this.searchProductCate = '';

      const item = {
        id: v4(),
        rewardProgramCategoryId: option.item.id,
        name: option.item.name,
        grossProfitTarget: 0,
        incomeTarget: 0,
        inchargeEmployeeId: null,
        inchargeEmployeeRewardRate: 0,
        adminId: null,
        adminRewardRate: 0,
        adminName: '',
        inchargeEmployeeName: '',
        billAmount1: 0,
        billAmountOperator1: 1,
        billAmount2: 0,
        billAmountOperator2: 1,
        isBelongDirector: false,
        baseBonusPoint: 0,
        baseBonusPointType: 'basic',
        baseRetailBonusPoint: 0,
        baseWholesaleBonusPoint: 0,
      };
      this.mainModel.details.push(item);
      this.mainModel.details.forEach((element, index) => {
        element.count = index + 1;
      });
    },
    onInputChangeProductCate(text) {
      this.searchProductCate = text;

      this.debounceInputCate();
    },
    getListEmployee() {
      ApiService.get('employees/getAll').then((data) => {
        const employees = data.data.data;
        this.optionsEmployee[0].data = employees.map((element) => {
          return {
            id: element.id,
            name: element.fullName,
          };
        });
      });
    },
    onBack: function () {
      history.back();
    },
    onSubmit: function () {
      //format unmask price
      this.mainModel.details.forEach((element) => {
        element.grossProfitTarget = unMaskPrice(element.grossProfitTarget);
        element.incomeTarget = unMaskPrice(element.incomeTarget);
        element.baseBonusPoint = unMaskPrice(element.baseBonusPoint);
        element.baseWholesaleBonusPoint = unMaskPrice(
          element.baseWholesaleBonusPoint
        );
        element.baseRetailBonusPoint = unMaskPrice(
          element.baseRetailBonusPoint
        );
        element.billAmount1 = element.billAmount1
          ? unMaskPrice(element.billAmount1)
          : 0;
        element.billAmount2 = element.billAmount2
          ? unMaskPrice(element.billAmount2)
          : 0;
      });
      this.mainModel.rewardFees.forEach((element) => {
        element.amount = unMaskPrice(element.amount);
      });
      this.mainModel.startDate = moment(
        this.mainModel.startDate,
        'DD/MM/yyyy'
      ).toDate();
      this.mainModel.startDate = this.mainModel.startDate
        ? moment(this.mainModel.startDate, 'DD/MM/YYYY').valueOf()
        : null;
      this.mainModel.endDate = this.mainModel.endDate
        ? moment(this.mainModel.endDate, 'DD/MM/YYYY').valueOf()
        : null;
      const data = {
        id: this.mainModel.id,
        name: this.mainModel.name,
        description: this.mainModel.description,
        isActive: this.mainModel.isActive,
        startDate: this.mainModel.startDate,
        endDate: this.mainModel.endDate,
        details: this.mainModel.details,
        rewardFees: this.mainModel.rewardFees,
        grossProfitTarget: unMaskPrice(this.mainModel.grossProfitTarget),
        incomeTarget: unMaskPrice(this.mainModel.incomeTarget),
        inventoryValueTarget: unMaskPrice(this.mainModel.inventoryValueTarget),
        inventoryValuePoint: unMaskPrice(this.mainModel.inventoryValuePoint),
        inventoryTimePoint: unMaskPrice(this.mainModel.inventoryTimePoint),
        incomePoint: unMaskPrice(this.mainModel.incomePoint),
        grossProfitPoint: unMaskPrice(this.mainModel.grossProfitPoint),
        inventoryTimeTarget: unMaskPrice(this.mainModel.inventoryTimeTarget),
        baseWholesaleBonusPoint: unMaskPrice(
          this.mainModel.baseWholesaleBonusPoint
        ),
        baseRetailBonusPoint: unMaskPrice(this.mainModel.baseRetailBonusPoint),
        baseBonusPoint: unMaskPrice(this.mainModel.baseBonusPoint),
        directorId: this.mainModel.directorId,
      };
      if (!this.mainModel.id) {
        this.onCallCreateApi(data);
      } else {
        this.onCallUpdateApi(data);
      }
    },
    onCallCreateApi(data) {
      ApiService.post('reward-phone/', data)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            this.makeToastSuccess(message);
            history.back();
          } else {
            this.makeToastFailure(message);
          }
        })
        .catch((err) => {
          const message = err.response.data.message;
          this.makeToastFailure(message);
        });
    },
    onCallUpdateApi(data) {
      ApiService.put('reward-phone/', data)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            this.makeToastSuccess(message);
            history.back();
          } else {
            this.makeToastFailure(message);
          }
        })
        .catch((err) => {
          const message = err.response.data.message;
          this.makeToastFailure(message);
        });
    },
    debounceInputCate: decounce(function () {
      const textSearch = this.searchProductCate
        ? this.searchProductCate.trim()
        : null;
      if (!this.isSearching) {
        this.fetchProductCate(textSearch);
      }
    }, TIME_TRIGGER),
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFailure(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    showModalEmployeeRewardDetail(item) {
      this.detailEmployeeReward = {
        id: item.id,
        name: item.name,
        grossProfitTarget: item.grossProfitTarget,
        incomeTarget: item.incomeTarget,
        inchargeEmployeeId: item.inchargeEmployeeId,
        inchargeEmployeeName: item.inchargeEmployeeName,
        inchargeEmployeeRewardRate: item.inchargeEmployeeRewardRate,
        adminId: item.adminId,
        adminName: item.adminName,
        adminRewardRate: item.adminRewardRate,
        billAmount1: item.billAmount1,
        billAmountOperator1: item.billAmountOperator1,
        billAmount2: item.billAmount2,
        billAmountOperator2: item.billAmountOperator2,
        rewardProgramCategoryId: item.rewardProgramCategoryId,
        count: item.count,
        isBelongDirector: item.isBelongDirector,
        baseBonusPointType: item.baseBonusPointType,
        baseBonusPoint: item.baseBonusPoint,
        baseRetailBonusPoint: item.baseRetailBonusPoint,
        baseWholesaleBonusPoint: item.baseWholesaleBonusPoint,
      };

      this.currentindex = this.mainModel.details.findIndex(
        (detail) => detail.count == item.count
      );
      this.$refs['employee-reward-detail-modal'].show();
    },
    hideModalEmployeeRewardDetail() {
      this.$refs['employee-reward-detail-modal'].hide();
    },
    saveEmployeeRewardDetail() {
      if (
        (this.detailEmployeeReward.billAmount1 &&
          (!this.detailEmployeeReward.billAmountOperator1 ||
            this.detailEmployeeReward.billAmountOperator1 == 1)) ||
        (!this.detailEmployeeReward.billAmount1 &&
          !(
            !this.detailEmployeeReward.billAmountOperator1 ||
            this.detailEmployeeReward.billAmountOperator1 == 1
          ))
      ) {
        const inputError = !this.detailEmployeeReward.billAmount1
          ? 'Giá trị 1'
          : 'Toán tử 1';
        return this.makeToastFailure(`Doanh thu hoá đơn. Nhập ${inputError}`);
      }
      if (
        (this.detailEmployeeReward.billAmount2 &&
          (!this.detailEmployeeReward.billAmountOperator2 ||
            this.detailEmployeeReward.billAmountOperator2 == 1)) ||
        (!this.detailEmployeeReward.billAmount2 &&
          !(
            !this.detailEmployeeReward.billAmountOperator2 ||
            this.detailEmployeeReward.billAmountOperator2 == 1
          ))
      ) {
        const inputError = !this.detailEmployeeReward.billAmount2
          ? 'Giá trị 2'
          : 'Toán tử 2';
        return this.makeToastFailure(`Doanh thu hoá đơn. Nhập ${inputError}`);
      }
      if (
        this.detailEmployeeReward.billAmount1 &&
        this.detailEmployeeReward.billAmount2 &&
        Number(unMaskPrice(this.detailEmployeeReward.billAmount1)) >
          Number(unMaskPrice(this.detailEmployeeReward.billAmount2))
      ) {
        return this.makeToastFailure(
          `Doanh thu hoá đơn. Giá trị 1 phải nhỏ hơn Giá trị 2.`
        );
      }

      this.mainModel.details.forEach((element) => {
        if (element.count === this.detailEmployeeReward.count) {
          Object.assign(element, this.detailEmployeeReward);
        }
      });

      this.hideModalEmployeeRewardDetail();
    },
    onInputChangeDirector(text) {
      if (!text) {
        text = '';
      }
      this.searchDirector = text;

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.optionsEmployee[0].data
        .filter((item) => {
          let nameNoneSign = xoa_dau(item.name);
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionsEmployee = [
        {
          data: filteredData,
        },
      ];
    },
    onSelectedDirector(option) {
      this.searchDirector = option.item.name;
      this.mainModel.directorId = option.item.id;
    },
    onInputChangeDirectorAdmin(text) {
      if (!text) {
        text = '';
      }
      this.searchDirectorAdmin = text;
      const filteredData = this.optionsEmployee[0].data
        .filter((item) => {
          let nameNoneSign = xoa_dau(item.name);
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionsEmployee = [
        {
          data: filteredData,
        },
      ];
    },
    onInputChangeInchargeEmployee(text) {
      if (!text) {
        text = '';
      }

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.optionsEmployee[0].data
        .filter((item) => {
          let nameNoneSign = xoa_dau(item.name);
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionsEmployee = [
        {
          data: filteredData,
        },
      ];
    },
    onSelectedInchargeEmployee(option) {
      this.detailEmployeeReward.inchargeEmployeeId = option.item.id;
      this.detailEmployeeReward.inchargeEmployeeName = option.item.name;
    },
    onInputChangeAdmin(text) {
      if (!text) {
        text = '';
      }
      const filteredData = this.optionsEmployee[0].data
        .filter((item) => {
          let nameNoneSign = xoa_dau(item.name);
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionsEmployee = [
        {
          data: filteredData,
        },
      ];
    },
    onSelectedAdmin(option) {
      this.detailEmployeeReward.adminId = option.item.id;
      this.detailEmployeeReward.adminName = option.item.name;
    },
    getInfoById: function () {
      ApiService.get('reward-phone/' + this.mainModel.id).then((res) => {
        const {
          name,
          description,
          startDate,
          endDate,
          isActive,
          details,
          rewardFees,
          directorId,
          directorName,
          baseBonusPoint,
          baseRetailBonusPoint,
          baseWholesaleBonusPoint,
          grossProfitTarget,
          incomeTarget,
          inventoryValueTarget,
          inventoryValuePoint,
          inventoryTimePoint,
          grossProfitPoint,
          incomePoint,
          inventoryTimeTarget,
        } = res.data.data;
        this.mainModel.name = name;
        this.mainModel.description = description;
        this.mainModel.startDate =
          startDate !== null ? moment(startDate).format('DD/MM/YYYY') : '';
        this.mainModel.endDate =
          endDate !== null ? moment(endDate).format('DD/MM/YYYY') : '';
        this.mainModel.isActive = isActive;
        this.mainModel.details = details;
        this.mainModel.details.forEach((element, index) => {
          element.count = index + 1;
          element.baseBonusPointType =
            element.baseBonusPoint ||
            (!element.baseWholesaleBonusPoint && !element.baseRetailBonusPoint)
              ? 'basic'
              : 'advanced';
        });
        this.mainModel.rewardFees = rewardFees;
        this.mainModel.rewardFees.forEach((element, index) => {
          element.count = index + 1;
        });
        this.mainModel.directorId = directorId;
        this.searchDirector = directorName;
        this.mainModel.baseRetailBonusPoint = baseRetailBonusPoint;
        this.mainModel.baseBonusPoint = baseBonusPoint;
        this.mainModel.baseWholesaleBonusPoint = baseWholesaleBonusPoint;
        this.mainModel.baseBonusPointType =
          baseBonusPoint || (!baseWholesaleBonusPoint && !baseRetailBonusPoint)
            ? 'basic'
            : 'advanced';
        this.mainModel.grossProfitTarget = grossProfitTarget;
        this.mainModel.incomeTarget = incomeTarget;
        this.mainModel.inventoryValueTarget = inventoryValueTarget;
        this.mainModel.inventoryValuePoint = inventoryValuePoint;
        this.mainModel.inventoryTimePoint = inventoryTimePoint;
        this.mainModel.grossProfitPoint = grossProfitPoint;
        this.mainModel.incomePoint = incomePoint;
        this.mainModel.inventoryTimeTarget = inventoryTimeTarget;
        const mode = this.$route.query.mode;
        if (mode !== 'update') {
          this.mainModel.id = null;
        }
      });
    },
    onDelete(items) {
      this.mainModel.rewardFees = items;
    },
    onInputBonusPoint: function (input) {
      if (!input) {
        return;
      }

      if (this.mainModel.baseBonusPointType === 'basic') {
        this.mainModel.baseWholesaleBonusPoint = 0;
        this.mainModel.baseRetailBonusPoint = 0;
      } else {
        this.mainModel.baseBonusPoint = 0;
      }
    },
    onInputBonusPointDetail: function (input) {
      if (!input) {
        return;
      }

      if (this.detailEmployeeReward.baseBonusPointType === 'basic') {
        this.detailEmployeeReward.baseWholesaleBonusPoint = 0;
        this.detailEmployeeReward.baseRetailBonusPoint = 0;
      } else {
        this.detailEmployeeReward.baseBonusPoint = 0;
      }
    },
  },
};
</script>

<style>
.rounded {
  border-radius: 0 !important;
}

.form-group label {
  font-weight: 500;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
.productCode:hover {
  text-decoration: underline;
}
.row-center-vertical {
  display: flex;
  align-items: center;
}
</style>
